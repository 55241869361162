import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@client/utils/auth';
import { sendSuccessNotification, sendFailureNotification } from '../../utils/toastNotifications';

export interface Chassis {
  id: string;
  chassisPoolUuid?: string | null;
  chassisPoolUuidOverride?: string | null;
  triaxle?: boolean;
  extendable?: boolean;
  inRotation?: boolean;
  chassisSupportedSize: string[];
  chassisProviderUuid?: string | null;
  lastPoolUpdated?: Date | null;
  lastProviderUpdated?: Date | null;
  isPoolScraped?: boolean;
  isProviderScraped?: boolean;
}

export interface ChassisSupportSizes {}

const initialState = {
  loading: false,
  error: false
};

export const fetchChassis = createAsyncThunk('chassis/fetchAll', async () => {
  const authHeader = getAuthToken();

  const options: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  const url = api('/chassis');

  try {
    const response = await request(url, options);
    return response.data;
  } catch (err) {
    const message = 'Unable to fetch chassis information';
    sendFailureNotification(message, 'top-left');
  }
});

export const fetchChassisByIds = createAsyncThunk('chassis/fetchByIds', async (chassisNumbers: string[]) => {
  if (!chassisNumbers?.length) {
    return [];
  }
  const url = api(`/chassis?id=${chassisNumbers.join(',')}`);
  try {
    const response = (await request(url)) as { data: any[] };
    return response.data;
  } catch (err) {
    const message = 'Unable to fetch chassis';
    sendFailureNotification(message, 'top-left');
  }
});

export const deleteBulkChassis = createAsyncThunk('chassis/deleteBulkChassis', async (chassisIds: string[]) => {
  try {
    const url = api('/chassis');
    const opts = { method: 'DELETE', body: JSON.stringify({ chassisIds }) };
    await request(url, opts);
    return chassisIds;
  } catch (err) {
    const message = 'Unable to delete chassis.';
    sendFailureNotification(message, 'top-left');
  }
});

export const putBulkChassis = createAsyncThunk('chassis/putBulkChassis', async (chassis: Chassis[]) => {
  if (!chassis.length) {
    return;
  }
  const url = api('/chassis');
  const body = JSON.stringify({ data: chassis });
  const options = { method: 'PUT', body };

  try {
    const response = (await request(url, options)) as any;
    return response.data;
  } catch (err) {
    const { message } = (err as Error) || {};
    sendFailureNotification(message || 'Unable to create or update chassis.', 'top-left');
  }
});

export const chassisAdapter = createEntityAdapter<any>();

const chassisSlice = createSlice({
  name: 'core/chassisSlice',
  initialState: chassisAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChassis.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];

      if (action.payload) {
        chassisAdapter.setAll(state, data);
      }
    });

    builder.addCase(fetchChassisByIds.fulfilled, (state, action) => {
      if (action.payload) {
        chassisAdapter.upsertMany(state, action.payload);
      }
    });

    // PUT
    builder.addCase(putBulkChassis.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(putBulkChassis.fulfilled, (state, action) => {
      state.loading = true;
      const data = action.payload || [];

      if (action.payload) {
        chassisAdapter.upsertMany(state, data);
        const message = 'Chassis created or updated!';
        sendSuccessNotification(message, 'top-left');
      }
    });

    // DELETE
    builder.addCase(deleteBulkChassis.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkChassis.fulfilled, (state, action) => {
      state.loading = true;
      const deletedChassisIds = action.payload || [];

      if (action.payload) {
        chassisAdapter.removeMany(state, deletedChassisIds);
        const message = 'Chassis Deleted!';
        sendSuccessNotification(message, 'top-left');
      }
    });
  }
});

const selectStore = (state: any) => state;

export const selectChassis = (state: any) => state.core.chassis;

export const { selectAll: selectAllChassis, selectById: selectChassisById } =
  chassisAdapter.getSelectors<any>(selectChassis);

export const filteredChassisSelectorFactory = (rotationType = true) =>
  createSelector(selectAllChassis as any, (chassis: Chassis[]) => {
    const result = chassis.filter(({ inRotation }) => inRotation === rotationType);
    return result;
  });

export const selectChassisByTermFactory = (query: string, limit = 50) =>
  createSelector(selectAllChassis as any, (chassis: Chassis[]) => {
    if (!query) {
      return chassis.slice(0, limit);
    }

    const parsedString = query.split(/[, ]+/);
    const result = parsedString.reduce((acc, str) => {
      chassis.forEach((c) => {
        if (c.id.toLowerCase().search(str.toLowerCase()) !== -1) {
          acc.push(c);
        }
      });
      return acc;
    }, [] as Chassis[]);
    return result.slice(0, limit);
  });

export const chassisByIdSelectorFactory = (chassisNumber: string) =>
  createSelector(selectStore, (state) => selectChassisById(state, chassisNumber));

export default chassisSlice.reducer;
