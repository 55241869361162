import { createSelector } from 'reselect';
import { EntityState } from '@reduxjs/toolkit';
import { consigneeDeliveryPreferencesAdapter } from '.';
import { ConsigneeDeliveryPreferences } from './model';

export const selectConsigneeDeliveryPreferencesSlice = (state: any): EntityState<ConsigneeDeliveryPreferences> =>
  state.core.consigneeDeliveryPreferences;

export const { selectAll, selectEntities } = consigneeDeliveryPreferencesAdapter.getSelectors(
  selectConsigneeDeliveryPreferencesSlice
);

export const consigneeDeliveryPreferencesByConsigneeUuidAndTypeSelectorFactory = (
  consigneeUuid: string,
  type: 'import' | 'export'
) =>
  createSelector(selectConsigneeDeliveryPreferencesSlice, (store) => {
    const allItems = Object.values(store.entities) as ConsigneeDeliveryPreferences[];

    return allItems.find((cdp) => cdp.attributes.departmentUuid === consigneeUuid && cdp.attributes.type === type);
  });

export const apptOutreachEnabledSelectorFactory = (consigneeUuid: string, importExport: string) => {
  const type = importExport === 'export' ? 'export' : 'import';
  return createSelector(
    consigneeDeliveryPreferencesByConsigneeUuidAndTypeSelectorFactory(consigneeUuid, type),
    (cdp) => cdp?.attributes.apptNeeded && cdp?.attributes.apptOutreachEnabled
  );
};
