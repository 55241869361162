import request, { requestRules } from '@client/utils/request';
import { api } from '@client/utils/url';

export const retrieveDrivers = async (_: void, opts?: { abortKey?: string }): Promise<any> => {
  const url = api('/users?include=departments&departmentTypes=carrier');
  const options: Parameters<typeof request>[1] = {
    method: 'GET',
    credentials: 'same-origin',
    details: opts?.abortKey
      ? {
          id: opts.abortKey,
          rule: requestRules.TAKE_LAST
        }
      : undefined
  };

  const response: any = await request(url, options);
  return response;
};
