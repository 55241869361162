import { getAuthToken } from '@client/utils/auth';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import type { RootState } from '@client/reduxProvider';
import { User } from './model';
import { SetLocalUserActionPayload } from './types';

export const selectUsers = (state: RootState) => state.core.users;

export const fetchUsersForDepartment = createAsyncThunk<User[] | undefined, string, { state: RootState }>(
  'users/fetchForDepartment',
  async (departmentUuid: string) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/v2/departments/${departmentUuid}/users`);

    try {
      const { data } = (await request<Record<'data', User[]>>(url, options)) || { data: [] };

      return data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch users for Department" />);
    }
  }
);

export const fetchAllUsers = createAsyncThunk<User[] | undefined, void, { state: RootState }>(
  'users/fetchAll',
  async () => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/v2/users`);

    try {
      const { data } = (await request<Record<'data', User[]>>(url, options)) || { data: [] };

      return data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch all Users" />);
    }
  },
  {
    condition: (_, { getState }) => {
      const { loadingAllUsers } = selectUsers(getState());

      if (loadingAllUsers) {
        return false;
      }
    }
  }
);

export const fetchUserByUuid = createAsyncThunk<User | undefined, string, { state: RootState }>(
  'users/fetchByUuid',
  async (userUuid: string) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/v2/users/${userUuid}`);

    try {
      return await request(url, options);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Users" />);
    }
  },
  {
    condition: (userUuid, { getState }) => {
      const { loadingByUserUuid } = selectUsers(getState());

      if (loadingByUserUuid[userUuid]) {
        return false;
      }
    }
  }
);

export interface UsersStateSlice {
  usersByDepartmentUuid: Record<string, User[]>;
  usersByUuid: Record<string, User>;
  loadingUsersByDepartmentUuid: boolean;
  loadingAllUsers: boolean;
  loadingByUserUuid: Record<string, boolean>;
}

const initialState: UsersStateSlice = {
  usersByDepartmentUuid: {},
  usersByUuid: {},
  loadingUsersByDepartmentUuid: false,
  loadingAllUsers: false,
  loadingByUserUuid: {}
};

const usersSlice = createSlice({
  name: 'core/users',
  initialState,
  reducers: {
    setLocalUserValue(state, action) {
      const { userUuid, userField, value } = action.payload as SetLocalUserActionPayload;
      if (state.usersByUuid[userUuid]) {
        state.usersByUuid[userUuid] = {
          ...state.usersByUuid[userUuid],
          [userField]: value
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersForDepartment.pending, (state) => {
      state.loadingUsersByDepartmentUuid = true;
    });
    builder.addCase(fetchUsersForDepartment.rejected, (state, action) => {
      state.loadingUsersByDepartmentUuid = false;
      state.usersByDepartmentUuid[action.meta.arg] = [];
    });
    builder.addCase(fetchUsersForDepartment.fulfilled, (state, action) => {
      state.loadingUsersByDepartmentUuid = false;

      state.usersByDepartmentUuid[action.meta.arg] = action.payload || [];
      // eslint-disable-next-line no-restricted-syntax
      for (const fetchedUser of action.payload || []) {
        state.usersByUuid[fetchedUser.uuid] = fetchedUser;
      }
    });
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.loadingAllUsers = true;
    });
    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.loadingAllUsers = false;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.loadingAllUsers = false;
      const payload = action.payload || [];
      payload.forEach((user) => {
        if (!state.usersByUuid[user.uuid]) {
          state.usersByUuid[user.uuid] = user;
        } else {
          state.usersByUuid[user.uuid] = {
            ...state.usersByUuid[user.uuid],
            ...user
          };
        }
      });
    });
    builder.addCase(fetchUserByUuid.pending, (state, action) => {
      state.loadingByUserUuid[action.meta.arg] = true;
    });
    builder.addCase(fetchUserByUuid.rejected, (state, action) => {
      state.loadingByUserUuid[action.meta.arg] = false;
    });
    builder.addCase(fetchUserByUuid.fulfilled, (state, action) => {
      if (action.payload) {
        state.usersByUuid[action.meta.arg] = {
          ...state.usersByUuid[action.meta.arg],
          ...action.payload
        };
      }
      state.loadingByUserUuid[action.meta.arg] = false;
    });
  }
});

export const selectAllUsersLoading = createSelector(selectUsers, (users) => users.loadingAllUsers);

export const selectUsersLoadingByDepartmentUuid = createSelector(
  selectUsers,
  (users) => users.loadingUsersByDepartmentUuid
);

export const selectAllUsers = createSelector(selectUsers, (users) => Object.values(users.usersByUuid));

export const selectUsersByDepartmentUuid = createSelector(selectUsers, (users) => users.usersByDepartmentUuid);

export const selectUsersByUuid = createSelector(selectUsers, (users) => users.usersByUuid);

export const { setLocalUserValue } = usersSlice.actions;

export default usersSlice.reducer;
