import request, { requestRules } from '@client/utils/request';
import { api } from '@client/utils/url';

interface FetchOptions {
  abortKey?: string;
}

export const fetchDualInMatches = async (params: { legID: string }, opts?: FetchOptions): Promise<any> => {
  const url = api(`/smart-matches/dual-ingates/leg/${params.legID}`);
  const options: Parameters<typeof request>[1] = {
    method: 'GET',
    credentials: 'same-origin',
    details: opts?.abortKey
      ? {
          id: opts.abortKey,
          rule: requestRules.TAKE_LAST
        }
      : undefined
  };
  const resp = request(url, options);
  return resp;
};

export const fetchDualIngateGeneratorBundles = async (
  params: { legNumber: number },
  opts?: FetchOptions
): Promise<any> => {
  const url = api(`/bundle/generator/bundle?endsWithLeg=${params.legNumber}&legCount=2`);
  const options: Parameters<typeof request>[1] = {
    method: 'GET',
    credentials: 'same-origin',
    details: opts?.abortKey
      ? {
          id: opts.abortKey,
          rule: requestRules.TAKE_LAST
        }
      : undefined
  };
  const resp = request(url, options);
  return resp;
};

export const fetchGeneratorLegs = async (legNumbers: number[] = [], opts?: FetchOptions): Promise<any> => {
  if (!legNumbers.length) {
    return { data: [] };
  }
  const url = api(`/bundle/generator/leg?legNumber=${legNumbers.join(',')}`);
  const options: Parameters<typeof request>[1] = {
    method: 'GET',
    credentials: 'same-origin',
    details: opts?.abortKey
      ? {
          id: opts.abortKey,
          rule: requestRules.TAKE_LAST
        }
      : undefined
  };
  const resp = request(url, options);
  return resp;
};
