import React from 'react';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@client/reduxProvider';
import { api } from '../../../../utils/url';
import request from '../../../../utils/request';
import { raiseToast } from '../../../../components/Toaster';
import SystemToast from '../../../../components/SystemToast';

const sliceID = 'emptyReturnAcceptancePage';

export interface EmptyReturnAcceptanceItem {
  id: number;
  terminalUuid: string;
  sslUuid: string;
  sizeUuid: string;
  returnDate: string;
  shift: 0 | 1;
  accepting: 'yes' | 'no' | 'dual';
  source: 'optimizer' | 'override';
}

export const fetchEmptyReturnAcceptance = createAsyncThunk(`${sliceID}/fetchAll`, async () => {
  const url = api(`/empty-return-locations?shiftCount=8`);

  try {
    const result = (await request(url)) as { data: EmptyReturnAcceptanceItem[] };
    return result.data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch empty return acceptance" />);
  }
});

export const scrapeEmptyReturnAcceptance = createAsyncThunk(`${sliceID}/scrape`, async () => {
  const url = api(`/empty-return-locations/populate`);

  try {
    await request(url, { method: 'POST' });
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to scrape empty return acceptance" />);
  }
});

export const emptyReturnAcceptanceAdapter = createEntityAdapter<EmptyReturnAcceptanceItem>({
  selectId: (item: EmptyReturnAcceptanceItem) => item.id
});

const emptyReturnAcceptanceSlice = createSlice({
  name: `core/emptyReturnAcceptance`,
  initialState: { scraping: false, ...emptyReturnAcceptanceAdapter.getInitialState() },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmptyReturnAcceptance.fulfilled, (state, action) => {
      const data = action.payload || [];

      emptyReturnAcceptanceAdapter.setAll(state, data);
    });
    builder.addCase(scrapeEmptyReturnAcceptance.pending, (state) => {
      state.scraping = true;
    });
    builder.addCase(scrapeEmptyReturnAcceptance.fulfilled, (state) => {
      state.scraping = false;
    });
    builder.addCase(scrapeEmptyReturnAcceptance.rejected, (state) => {
      state.scraping = false;
    });
  }
});

export const selectEmptyReturnAcceptance = (state: RootState): EmptyReturnAcceptanceItem[] =>
  Object.values(state.core.emptyReturnAcceptance.entities) as EmptyReturnAcceptanceItem[];

export const selectEmptySupportedMapByTerminalSslAndSizeUuid = createSelector(
  selectEmptyReturnAcceptance,
  (emptyReturnSupported) =>
    emptyReturnSupported.reduce((acc: Record<string, any>, era) => {
      const terminalAndSslAndSizeUuid = `${era.terminalUuid}::${era.sslUuid}::${era.sizeUuid}`;
      if (acc[terminalAndSslAndSizeUuid] === undefined) {
        acc[terminalAndSslAndSizeUuid] = [];
      }
      acc[terminalAndSslAndSizeUuid].push(era);
      return acc;
    }, {})
);

export const selectEmptySupportedMapByTerminalUuid = createSelector(
  selectEmptyReturnAcceptance,
  (emptyReturnSupported) =>
    emptyReturnSupported.reduce((acc: Record<string, any>, era) => {
      const { terminalUuid } = era;
      if (acc[terminalUuid] === undefined) {
        acc[terminalUuid] = [];
      }
      acc[terminalUuid].push(era);
      return acc;
    }, {})
);

export const selectScraping = (state: any) => state.core.emptyReturnAcceptance.scraping;

export const selectEmptyReturnAcceptanceTerminals = (state: any) =>
  Array.from(
    new Set(
      Object.values(state.core.emptyReturnAcceptance.entities as Record<string, EmptyReturnAcceptanceItem>).map(
        (er: EmptyReturnAcceptanceItem) => er.terminalUuid
      )
    )
  );

export const selectEmptyReturnAcceptanceSsls = (state: any) =>
  Array.from(
    new Set(
      Object.values(state.core.emptyReturnAcceptance.entities as Record<string, EmptyReturnAcceptanceItem>).map(
        (er: EmptyReturnAcceptanceItem) => er.sslUuid
      )
    )
  );

export const createSelectLegEmptyReturnAcceptance = ({
  sslUuid,
  sizeUuid,
  returnDate,
  shift
}: {
  sslUuid: string;
  sizeUuid: string | undefined;
  returnDate: string;
  shift: 0 | 1;
}) =>
  createSelector(selectEmptyReturnAcceptance, (emptyReturnSupported) => {
    const foo = Array.from(
      new Set(
        emptyReturnSupported.filter(
          (er) =>
            er &&
            er.sslUuid === sslUuid &&
            er.sizeUuid === sizeUuid &&
            er.returnDate === returnDate &&
            er.shift === shift &&
            er.accepting !== 'no'
        ) as EmptyReturnAcceptanceItem[]
      )
    );

    return foo;
  });

export const retrieveEmptyReturnAcceptanceAction = createAsyncThunk(
  `${sliceID}/retrieveEmptyReturnAcceptance`,
  async (params: {}, thunkAPI) => {
    try {
      const resp = await fetchEmptyReturnAcceptance();
      return await thunkAPI.dispatch(resp);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load empty return acceptance" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const scrapeEmptyReturnAcceptanceAction = createAsyncThunk(
  `${sliceID}/scrapeEmptyReturnAcceptance`,
  async (params: {}, thunkAPI) => {
    try {
      await thunkAPI.dispatch(scrapeEmptyReturnAcceptance());
      return await thunkAPI.dispatch(retrieveEmptyReturnAcceptanceAction({}));
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load empty return acceptance" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export default emptyReturnAcceptanceSlice.reducer;
