import { SmartMatch } from '@client/_blessed/models/SmartMatch';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { sliceID } from '../../components/features/Admin/features/YardContainers/store/utils';

export interface SmartMatchResponse {
  data: Record<string, SmartMatch[]>;
}

export const retrieveSmartMatches = createAsyncThunk(
  `${sliceID}/retrieveSmartMatches`,
  async ({ ssls, size }: { ssls: string[]; size: string }) => {
    const url = api(`/smart-matches/dual-outgates?ssl=${ssls.join(',')}&size=${size}`);
    const resp = request(url);
    return resp;
  }
);

export const fetchGeneratorSmartMatches = async (params: { legNumber: number }) => {
  const url = api(`/bundle/generator/bundle?startsWithLeg=${params.legNumber}&legCount=2`);
  const options: RequestInit = {
    method: 'GET',
    credentials: 'same-origin'
  };
  const resp = request(url, options);
  return resp;
};
