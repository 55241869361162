import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

export interface GeneratorBundleLeg {
  bundleId: string;
  legNumber: number;
  position: number;
}

export const fetchGeneratorBundleLegs = createAsyncThunk('core/generatorBundleLegs/fetch', async () => {
  const url = api('/bundle/generator/bundle-leg');

  try {
    const response = (await request(url)) as { data: any[] };
    return response.data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Generator Bundles" />);
  }
});

const getInitialState = () => ({
  status: 'idle',
  data: []
});

export const generatorBundleLegsSlice = createSlice({
  name: 'core/generatorBundleLegs',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGeneratorBundleLegs.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchGeneratorBundleLegs.fulfilled, (state: any, action) => {
      state.status = 'fulfilled';
      state.data = action.payload || [];
    });
  }
});

export const selectGeneratorBundleLegs = (state: any) => state.core.generatorBundleLegs;

export const selectAllGeneratorBundleLegs = createSelector(selectGeneratorBundleLegs, (slice) => slice.data);

export default generatorBundleLegsSlice.reducer;
