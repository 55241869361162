import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { retrieveDrivers } from '@client/_blessed/services/reactiveDualTrans/retrieveDrivers';
import type { RootState } from '@client/reduxProvider';
import { raiseToast } from '../../../../components/Toaster';
import SystemToast from '../../../../components/SystemToast';

const sliceId = 'core/driversSlice';

export const fetchDrivers = createAsyncThunk<{ data: any }>(`${sliceId}/retrieveDrivers`, async (_, thunkAPI) => {
  try {
    const resp = await retrieveDrivers(_, { abortKey: `${sliceId}/retrieveDrivers` });
    const labelledDrivers = resp.data?.map((item: any) => ({
      ...item,
      label: `${item.attributes.firstName} ${item.attributes.lastName}`
    }));
    return { data: labelledDrivers };
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable retrieve drivers" />);
    return thunkAPI.rejectWithValue(err);
  }
});

const driversAdapter = createEntityAdapter({
  selectId: (item: any) => item.id
});

const driversSlice = createSlice({
  name: 'core/driversSlice',
  initialState: driversAdapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: (builder) => {
    // drivers
    builder.addCase(fetchDrivers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDrivers.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload?.data || [];
      driversAdapter.setAll(state, data);
    });
    builder.addCase(fetchDrivers.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const selectDrivers = (state: RootState) => state.drivers;

export const selectDriversById = (state: RootState) => state.drivers.entities;

export const { selectAll: selectAllDrivers } = driversAdapter.getSelectors(selectDrivers);

export default driversSlice.reducer;
