import queryString from 'query-string';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { sendFailureNotification } from '@client/_blessed/store/utils/toastNotifications';
import request from '@client/utils/request';
import { getAuthToken } from '@client/utils/auth';
import { api } from '@client/utils/url';
import { Attachment, AttachmentTypes } from './entity';

export const fetchAttachmentsByType = createAsyncThunk(
  'attachments/fetchAttachmentsByType',
  async (params: Record<string, any>) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    let url = api(`/v2/attachments/?include=file`);

    if (params) {
      const qs = queryString.stringify(params);
      url += `&${qs}`;
    }

    try {
      const response = await request(url, options);
      return response.data;
    } catch (err) {
      const msg = 'Error retrieving per diem csv files.';
      sendFailureNotification(msg);
    }
  }
);

interface AttachmentsSliceState {
  attachmentsByTypes: Record<string, Attachment[]>;
  loadingAttachmentsByType: boolean;
}
const initialState: AttachmentsSliceState = {
  attachmentsByTypes: {},
  loadingAttachmentsByType: false
};

const attachmentsSlice = createSlice({
  name: 'dispatch/attachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAttachmentsByType.fulfilled, (state, action) => {
      if (action.payload) {
        const typeMap: Record<string, Attachment[]> = {};

        action.payload.forEach((item: Attachment) => {
          const { type } = item.attributes;
          if (typeMap[type] === undefined) {
            typeMap[type] = [];
          }
          typeMap[type].push(item);
        });

        state.attachmentsByTypes = { ...state.attachmentsByTypes, ...typeMap };
        state.loadingAttachmentsByType = false;
      }
    });
    builder.addCase(fetchAttachmentsByType.pending, (state) => {
      state.loadingAttachmentsByType = true;
    });
  }
});

const selectAttachmentsSlice = (state: any): AttachmentsSliceState => state.core.attachments as AttachmentsSliceState;

export const selectAttachmentByCsvPerDiem = createSelector(
  selectAttachmentsSlice,
  (state) => state.attachmentsByTypes[AttachmentTypes.PER_DIEM_CSV_DIGEST] || []
);

export const selectSortedAttachmentByCsvPerDiem = createSelector(selectAttachmentByCsvPerDiem, (data) =>
  [...data].sort((a, b) => {
    const bDate = new Date(b.attributes.createdAt).valueOf();
    const aDate = new Date(a.attributes.createdAt).valueOf();
    return bDate - aDate;
  })
);

export const selectAttachmentByTypeLoading = createSelector(
  selectAttachmentsSlice,
  (state) => state.loadingAttachmentsByType
);

export default attachmentsSlice.reducer;
