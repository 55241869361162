import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector, EntityState } from '@reduxjs/toolkit';
import { getAuthToken } from '@client/utils/auth';
import { selectChassisById } from '../chassis';
import { sendSuccessNotification, sendFailureNotification } from '../../utils/toastNotifications';

export interface ChassisProviderEntity {
  uuid: string;
  abbreviation: string;
  name: string | null;
  description: string | null;
}

export type ChassisProvidersEntityAdapter = { attributes: ChassisProviderEntity };

export interface ChassisProvidersState extends EntityState<any> {
  loading: boolean;
  error: boolean;
}

const initialState = {
  loading: false,
  error: false
};

export const fetchChassisProviders = createAsyncThunk('chassis-provider/fetchAll', async () => {
  const authHeader = getAuthToken();

  const options: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  const url = api('/chassis-provider');

  try {
    const response = await request(url, options);
    return response.data;
  } catch (err) {
    // handle toast error
    const message = 'Unable to fetch chassis Providers information';
    sendFailureNotification(message);
  }
});

export const upsertChassisProvider = createAsyncThunk(
  'chassis-provider/upsertChassisProvider',
  async (chassisProviderInfo: ChassisProviderEntity[]) => {
    try {
      const url = api('/chassis-provider');
      const opts = { method: 'PUT', body: JSON.stringify({ data: chassisProviderInfo }) };
      const { data } = await request(url, opts);
      return data;
    } catch (err) {
      const message = 'Unable to create or update chassis Providers.';
      sendFailureNotification(message);
    }
  }
);

export const deleteBulkChassisProvider = createAsyncThunk(
  'chassis-provider/deleteBulkChassisProviders',
  async (chassisProviderUuids: string[]) => {
    try {
      const url = api('/chassis-provider');
      const opts = { method: 'DELETE', body: JSON.stringify({ chassisProviderUuids }) };
      await request(url, opts);
      return chassisProviderUuids;
    } catch (err) {
      const message = 'Unable to delete chassis Providers.';
      sendFailureNotification(message);
    }
    // return data;
  }
);

export const chassisProvidersAdapter = createEntityAdapter<ChassisProviderEntity>({
  selectId: (chassisProviders: any) => chassisProviders?.uuid,
  sortComparer: (a, b) => a.abbreviation.localeCompare(b.abbreviation)
});

const chassisProvidersSlice = createSlice({
  name: 'core/chassisProvidersSlice',
  initialState: chassisProvidersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChassisProviders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchChassisProviders.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (action.payload) {
        chassisProvidersAdapter.setAll(state, data);
      }
    });

    // PUT
    builder.addCase(upsertChassisProvider.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertChassisProvider.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (action.payload) {
        chassisProvidersAdapter.upsertMany(state, data);
        const message = 'Chassis Provider created or updated!';
        return sendSuccessNotification(message);
      }
    });

    // DELETE
    builder.addCase(deleteBulkChassisProvider.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkChassisProvider.fulfilled, (state, action) => {
      state.loading = false;
      const deletedChassisProviderUuids = action.payload || [];

      if (action.payload) {
        chassisProvidersAdapter.removeMany(state, deletedChassisProviderUuids);
        const message = 'Chassis Providers Deleted!';
        return sendSuccessNotification(message);
      }
    });
  }
});

const selectChassisProviders = (state: any): ChassisProvidersState => state.core.chassisProviders;

export const {
  selectAll: selectAllChassisProviders,
  selectById: selectChassisProviderById,
  selectEntities: selectChassisProvidersById
} = chassisProvidersAdapter.getSelectors<any>(selectChassisProviders);

export const chassisProvidersForPut = (selectedUuid: string) =>
  createSelector(selectAllChassisProviders as any, (chassisProviders: ChassisProviderEntity[]) => {
    const result = chassisProviders.filter(({ uuid }) => selectedUuid === uuid);
    return result;
  });

const selectStore = (state: any) => state;

export const chassisProviderByIdSelectorFactory = (uuid: string) =>
  createSelector(selectStore, (state) => selectChassisProviderById(state, uuid));

export const selectChassisProviderDropdownOptions = createSelector(selectAllChassisProviders, (state) =>
  state.map((chassisProvider) => ({
    value: chassisProvider.uuid,
    label: chassisProvider.name
  }))
);

export const selectedChassisProviderDropdownOptionsWithNullSelection = createSelector(
  selectChassisProviderDropdownOptions,
  (state) => [{ value: 'N/A', label: 'N/A' }, ...state]
);

export const selectChassisProviderByTermFactory = (query: string) =>
  createSelector(selectAllChassisProviders as any, (chassisProvider: ChassisProviderEntity[]) => {
    const hashed = {} as { [uuid: string]: ChassisProviderEntity };
    const parsedString = query.split(/[, ]+/);

    parsedString.forEach((str) => {
      chassisProvider.forEach((cProvider) => {
        if (hashed[cProvider.uuid] === undefined) {
          if (cProvider.abbreviation.toLowerCase().search(str.toLowerCase()) !== -1) {
            hashed[cProvider.uuid] = cProvider;
          }
        }
      });
    });

    const result = Object.values(hashed) as ChassisProviderEntity[];
    return result;
  });

export const chassisProviderByChassisIdSelectorFactory = (chassisNumber: string) =>
  createSelector(selectStore, (state) => {
    const chassis = selectChassisById(state, chassisNumber);
    return selectChassisProviderById(state, chassis?.chassisProviderUuid);
  });

export default chassisProvidersSlice.reducer;
