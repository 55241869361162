import { combineReducers } from '@reduxjs/toolkit';
import _departments from './entities/departments';
import drivers from './entities/drivers';
import core from './entities';
import ediOrders from '../components/features/Edi/store';
import ediCreateOrders from '../components/features/EdiCreateOrders/store';
import createOrders from '../components/features/CreateOrders/store';
import arAp from '../components/features/ArAp/store';
import dispatch from '../components/features/Dispatch/store';
import register from '../components/features/Register/store';
import settings from '../components/features/Settings/store';
import admin, { sliceId } from '../components/features/Admin/store';

export const rootReducer = combineReducers({
  _departments,
  arAp,
  drivers,
  core,
  ediOrders,
  createOrders,
  dispatch,
  ediCreateOrders,
  register,
  settings,
  [sliceId]: admin
});
