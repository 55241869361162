import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@client/reduxProvider';
import { selectAllLegStopActionsByUuid } from '@client/_blessed/components/features/Dispatch/store';
import { LegStopActionToShortHandEnum } from '@drayalliance/types';
import Stop from './model';
import SystemToast from '../../../../components/SystemToast';
import { raiseToast } from '../../../../components/Toaster';
import { getAuthToken } from '../../../../utils/auth';
import request from '../../../../utils/request';
import { api } from '../../../../utils/url';

export const fetchStopsByLegUUIDs = createAsyncThunk(
  '_stops/fetchByLegUUIDs',
  async ({ legUUIDs }: { legUUIDs: string[]; opts?: { triggerLoadingState?: boolean } }) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/legs/stops?legUUIDs=${legUUIDs.join(',')}`);

    try {
      const stopsByLegUUID = (await request<Record<string, Stop[]>>(url, options)) || {};
      return stopsByLegUUID;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch stops by leg uuid" />);
    }
  }
);

export interface StopsStateSlice {
  loadingStopsByLegUUID: boolean;
  stopsByLegUUID: Record<string, Stop[]>;
}

const initialState: StopsStateSlice = {
  loadingStopsByLegUUID: false,
  stopsByLegUUID: {}
};

const stopsSlice = createSlice({
  name: 'core/stopsSlice',
  initialState,
  reducers: {
    updateLegStop: (state, action) => {
      state.stopsByLegUUID[action.payload.legUUID][action.payload.position] = action.payload.stop;
    },
    updateLegStops: (state, action) => {
      state.stopsByLegUUID[action.payload.legUUID] = action.payload.stops;
    },
    updateStopDepartureSIDepartureTime: (state, action) => {
      const stops = state.stopsByLegUUID[action.payload.legUUID];
      const stopToUpdate = stops.find((s) => s.uuid === action.payload.stopUUID);
      if (!stopToUpdate) {
        return;
      }
      stopToUpdate.departureStopInstant.actualDateTime = action.payload.actualDateTime;
    },
    updateStopArrivalSIDepartureTime: (state, action) => {
      const stops = state.stopsByLegUUID[action.payload.legUUID];
      const stopToUpdate = stops.find((s) => s.uuid === action.payload.stopUUID);
      if (!stopToUpdate) {
        return;
      }
      stopToUpdate.arrivalStopInstant.actualDateTime = action.payload.actualDateTime;
    },
    updateStopOpsInstruction: (state, action) => {
      const stops = state.stopsByLegUUID[action.payload.legUUID];
      const stopToUpdate = stops.find((s) => s.uuid === action.payload.stopUUID);
      if (!stopToUpdate) {
        return;
      }
      stopToUpdate.opsInstructions = action.payload.opsInstruction;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStopsByLegUUIDs.fulfilled, (state, action) => {
      const data = action.payload || {};

      state.loadingStopsByLegUUID = false;
      state.stopsByLegUUID = {
        ...state.stopsByLegUUID,
        ...data
      };
    });
    builder.addCase(fetchStopsByLegUUIDs.pending, (state, action) => {
      /**
       * In specific cases we might not want to trigger an entire page refresh. Defaults to always going into a loading state
       *   unless overriden not to
       *
       * ex. We open a side bar with a full dispatch page of 20 orders, we don't want everything on the dispatch page to go into a loading
       *   state and potentially re-fetch data it doesn't need to
       */
      if (action.meta.arg.opts?.triggerLoadingState ?? true) {
        state.loadingStopsByLegUUID = true;
      }
    });
    builder.addCase(fetchStopsByLegUUIDs.rejected, (state) => {
      state.loadingStopsByLegUUID = false;
    });
  }
});

const selectStopsSlice = (state: RootState) => state.core.stops;

export const selectLoadingStopsByLegUUID = createSelector([selectStopsSlice], (data) => data.loadingStopsByLegUUID);

export const selectStopsByLegUUID = createSelector([selectStopsSlice], (data) => data.stopsByLegUUID);

export const selectIsOutgate = (legUuid: string) =>
  createSelector([selectStopsByLegUUID, selectAllLegStopActionsByUuid], (stopsByLegUUID, legStopActionsByUuid) => {
    const stops = stopsByLegUUID[legUuid] || [];

    return stops.some((stop) => {
      const legStopAction = legStopActionsByUuid[stop.legStopActionUuid];

      if (!legStopAction) {
        return false;
      }

      return (
        stop.type === 'terminal' &&
        (legStopAction.shortHand === LegStopActionToShortHandEnum.PICK_UP_LOADED_CONTAINER ||
          legStopAction.shortHand === LegStopActionToShortHandEnum.PICK_UP_LOADED_CONTAINER_WITH_CHASSIS)
      );
    });
  });

export const selectIsIngate = (legUuid: string) =>
  createSelector([selectStopsByLegUUID, selectAllLegStopActionsByUuid], (stopsByLegUUID, legStopActionsByUuid) => {
    const stops = stopsByLegUUID[legUuid] || [];

    return stops.some((stop) => {
      const legStopAction = legStopActionsByUuid[stop.legStopActionUuid];

      if (!legStopAction) {
        return false;
      }

      return (
        stop.type === 'terminal' &&
        (legStopAction.shortHand === LegStopActionToShortHandEnum.DROP_OFF_EMPTY_CONTAINER ||
          legStopAction.shortHand === LegStopActionToShortHandEnum.DROP_OFF_EMPTY_CONTAINER_WITH_CHASSIS)
      );
    });
  });

export const selectFirstAppointment = (legUuid: string) =>
  createSelector([selectStopsByLegUUID], (stopsByLegUUID) => {
    const stops = stopsByLegUUID[legUuid] || [];
    const firstStop = stops[0];
    return firstStop?.departureStopInstant?.appointmentStart;
  });

export const {
  updateLegStop,
  updateLegStops,
  updateStopDepartureSIDepartureTime,
  updateStopArrivalSIDepartureTime,
  updateStopOpsInstruction
} = stopsSlice.actions;
export default stopsSlice.reducer;
