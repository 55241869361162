// eslint-disable-next-line no-shadow
export enum AttachmentTypes {
  GATE_TICKET = 'gateticket',
  PIER_PASS_NOT_PAID = 'pierpass_not_paid',
  PER_DIEM_CSV_DIGEST = 'perDiemCsvDigest'
}

export interface Attachment {
  type: string;
  id: string;
  attributes: {
    name: string;
    type: string;
    ownerType: string;
    ownerUuid: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    fileUuid: string;
  };
  relationships: {
    file: {
      data: {
        type: string;
        id: string;
      };
    };
  };
}
