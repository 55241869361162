import React from 'react';
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from '../../../../utils/url';
import request from '../../../../utils/request';
import { raiseToast } from '../../../../components/Toaster';
import SystemToast from '../../../../components/SystemToast';

interface RetrieveMasterTicketProps {
  entityType: string;
  entityUuid: string;
}

interface CreateMasterTicketProps extends RetrieveMasterTicketProps {
  deliveryOrderTicketId: string;
  containerNumber: string;
}

interface UpsertMasterTicketProps extends RetrieveMasterTicketProps {
  newMasterTicketId: string;
}

interface ZendeskMasterTicket {
  id: string;
}

interface State {
  currentMasterTicket: ZendeskMasterTicket | null;
  error: (Error & { statusCode: number }) | null;
  loading: boolean;
}

interface RequestError {
  code: number;
  message: string;
  responseObj: any;
}

const sliceId = 'core/zendeskMasterTicketsSlice';

export const fetchZendeskMasterTicketForEntity = createAsyncThunk(
  `${sliceId}/fetchMasterTicketForEntity`,
  async ({ entityType, entityUuid }: RetrieveMasterTicketProps, thunkAPI) => {
    const url = api('/zendesk-master-tickets');
    return request(url, { method: 'GET', query: { entityType, entityUuid } }).catch((e: RequestError) => {
      if (e.code !== 404) {
        raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Error retrieving Zendesk Master Ticket" />);
      }

      return thunkAPI.rejectWithValue(e.responseObj);
    });
  }
);

export const createZendeskMasterTicket = createAsyncThunk(
  `${sliceId}/createZendeskMasterTicket`,
  async ({ entityType, entityUuid, deliveryOrderTicketId, containerNumber }: CreateMasterTicketProps, thunkAPI) => {
    const url = api('/zendesk-master-tickets');
    const body = JSON.stringify([
      { entityType, entityUuid, zendeskTicketId: Number(deliveryOrderTicketId), containerNumber }
    ]);
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await request(url, { method: 'POST', body, headers });
      return response?.[0];
    } catch (e) {
      let message = 'Error creating Zendesk Master Ticket';
      if ((e as RequestError).message?.includes('zendeskTicketId must be')) {
        message = 'A valid DO Ticket number must be provided before creating a Zendesk Master Ticket';
      }
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={message} />);
      return thunkAPI.rejectWithValue((e as RequestError).responseObj);
    }
  }
);

export const upsertZendeskMasterTicket = createAsyncThunk(
  `${sliceId}/upsertMasterTicket`,
  async ({ entityType, entityUuid, newMasterTicketId }: UpsertMasterTicketProps, thunkAPI) => {
    const url = api('/zendesk-master-tickets');
    return request(url, { method: 'PUT', query: { entityType, entityUuid, newMasterTicketId } }).catch(
      (e: RequestError) => {
        raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Error updating Zendesk Master Ticket" />);
        return thunkAPI.rejectWithValue(e.responseObj);
      }
    );
  }
);

const initialState: State = {
  currentMasterTicket: null,
  loading: false,
  error: null
};

const zendeskMasterTicketsSlice = createSlice({
  name: sliceId,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchZendeskMasterTicketForEntity.pending, (state) => {
      state.error = null;
      state.currentMasterTicket = null;
      state.loading = true;
    });
    builder.addCase(fetchZendeskMasterTicketForEntity.fulfilled, (state, action) => {
      state.error = null;
      state.currentMasterTicket = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchZendeskMasterTicketForEntity.rejected, (state, action) => {
      state.error = action.payload as Error & { statusCode: number };
      state.currentMasterTicket = null;
      state.loading = false;
    });
    builder.addCase(createZendeskMasterTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createZendeskMasterTicket.fulfilled, (state, action) => {
      const error = action.payload?.error;
      if (error) {
        raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={error} />);
      }
    });
    builder.addCase(createZendeskMasterTicket.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertZendeskMasterTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertZendeskMasterTicket.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertZendeskMasterTicket.rejected, (state) => {
      state.loading = false;
    });
  }
});

const rootSelector = (state: any) => state.core.zendeskMasterTickets;

export const selectZendeskMasterTicketState = createSelector(rootSelector, (state: State) => ({
  loadedMasterTicket: state.currentMasterTicket,
  loading: state.loading,
  error: state.error
}));

export default zendeskMasterTicketsSlice.reducer;
