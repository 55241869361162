import { YardContainerInfo, EmptyReturnSupported } from '@client/_blessed/models/YardContainerInfo';
import { api } from '../../../utils/url';
import request, { requestRules } from '../../../utils/request';

interface ContainerInfoResponse {
  data: YardContainerInfo[];
  emptyReturnsSupported: EmptyReturnSupported[];
}

export const retrieveYardContainers = async (
  params?: null,
  opts?: { abortKey?: string }
): Promise<ContainerInfoResponse | undefined> => {
  const options: Parameters<typeof request>[1] = {
    method: 'GET',
    credentials: 'same-origin',
    details: opts?.abortKey
      ? {
          id: opts.abortKey,
          rule: requestRules.TAKE_LAST
        }
      : undefined
  };

  const url = api(`/admin/containers`);
  const response: ContainerInfoResponse = (await request(url, options)) as ContainerInfoResponse;

  return response;
};
