import React from 'react';
import SystemToast from '@client/components/SystemToast';
import { raiseToast, TOAST_POSITION } from '@client/components/Toaster';

type Position = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

function getToastPosition(position: Position): string {
  let toastPosition;
  switch (position) {
    case 'top-left':
      toastPosition = TOAST_POSITION.TOP_LEFT;
      break;
    case 'top-right':
      toastPosition = TOAST_POSITION.TOP_RIGHT;
      break;
    case 'bottom-right':
      toastPosition = TOAST_POSITION.BOTTOM_RIGHT;
      break;
    case 'bottom-left':
      toastPosition = TOAST_POSITION.BOTTOM_LEFT;
      break;
    default:
      throw Error('No position found');
  }
  return toastPosition;
}

export function sendSuccessNotification(message: string, position: Position = 'bottom-left'): void {
  const toastPosition = getToastPosition(position);

  raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message={message} />, {
    position: toastPosition
  });
}

export function sendFailureNotification(message: string, position: Position = 'bottom-left'): void {
  const toastPosition = getToastPosition(position);

  raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={message} />, {
    position: toastPosition
  });
}
