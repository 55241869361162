import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { ContainerOnHireStatus } from '@client/_blessed/components/features/Admin/features/OnHireEntry/features/types';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export type OnHireMapByTerminal = Record<string, ContainerOnHireStatus>;

export interface ContainerOnStatusEntity {
  containerNumber: string;
  onHireStatusByTerminal: OnHireMapByTerminal;
}

interface FetchOneContainerOnHireStatusParams {
  containerNumber: string;
  orderNumber: number;
}

export const fetchOneContainerOnHireStatus = createAsyncThunk(
  'containerOnHireStatuses/fetchOne',
  async ({ containerNumber, orderNumber }: FetchOneContainerOnHireStatusParams) => {
    if (!containerNumber || !orderNumber) {
      return;
    }
    const url = api(`/v2/container-on-hire-statuses?orderNumbers=${orderNumber}`);
    try {
      const response = (await request(url)) as { data: ContainerOnHireStatus[] };
      const onHireStatusByTerminal =
        response?.data?.reduce((map: OnHireMapByTerminal, onHireStatus) => {
          const statusIsValid = moment().isBefore(onHireStatus.validUntilDate);
          if (statusIsValid) {
            map[onHireStatus.terminal.uuid] = onHireStatus;
          }
          return map;
        }, {}) || {};
      const entity = {
        containerNumber,
        onHireStatusByTerminal
      };
      return entity;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={`Unable to fetch on-hire status for container ${containerNumber} in order ${orderNumber}`}
        />
      );
    }
  }
);

export const fetchAllContainerOnHireStatuses = createAsyncThunk('containerOnHireStatuses/fetchAll', async () => {
  const url = api('/v2/container-on-hire-statuses');
  try {
    const response = (await request(url)) as { data: ContainerOnHireStatus[] };
    const entityMapByContainerNumber: Record<string, ContainerOnStatusEntity> = response?.data?.reduce(
      (map: Record<string, ContainerOnStatusEntity>, onHireStatus) => {
        const { containerNumber, terminal, validUntilDate } = onHireStatus;
        const statusIsValid = moment().isBefore(validUntilDate);
        if (statusIsValid) {
          map[containerNumber] = map[containerNumber] || { containerNumber, onHireStatusByTerminal: {} };
          map[containerNumber].onHireStatusByTerminal[terminal.uuid] = onHireStatus;
        }
        return map;
      },
      {}
    );
    return Object.values(entityMapByContainerNumber);
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch all container on-hire statuses" />);
  }
});

const containerOnHireStatusesAdapter = createEntityAdapter<ContainerOnStatusEntity>({
  selectId: (item: ContainerOnStatusEntity) => item.containerNumber
});

const containerOnHireStatusesSlice = createSlice({
  name: 'core/containerOnHireStatusesSlice',
  initialState: containerOnHireStatusesAdapter.getInitialState({
    loading: false
  }),
  reducers: {},
  extraReducers: (builder) => {
    // fetchOneContainerOnHireStatus
    builder.addCase(fetchOneContainerOnHireStatus.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload) {
        containerOnHireStatusesAdapter.upsertOne(state, action.payload);
      }
    });
    builder.addCase(fetchOneContainerOnHireStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOneContainerOnHireStatus.rejected, (state) => {
      state.loading = false;
    });

    // fetchAllContainerOnHireStatuses
    builder.addCase(fetchAllContainerOnHireStatuses.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload) {
        containerOnHireStatusesAdapter.upsertMany(state, action.payload);
      }
    });
    builder.addCase(fetchAllContainerOnHireStatuses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllContainerOnHireStatuses.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const selectContainerOnHireStatuses = (state: any) => state.core.containerOnHireStatuses;

export const { selectAll: selectAllContainerOnHireStatuses } =
  containerOnHireStatusesAdapter.getSelectors<any>(selectContainerOnHireStatuses);

export const selectContainerOnHireStatusesByContainerNumber = (state: any) => state.core.containerOnHireStatuses.entities;

export default containerOnHireStatusesSlice.reducer;
