import { combineReducers } from '@reduxjs/toolkit';
import containerSizes from './containerSizes';
import departments from './departments';
import emptyReturnAcceptance from './emptyReturnAcceptance';
import stops from './stops';
import containerOnHireStatuses from './containerOnHireStatuses';
import chassis from './chassis/index';
import chassisPools from './chassisPools/index';
import chassisProviders from './chassisProviders/index';
import { terminalScraperConfigurationsReducer } from './terminalScraperConfiguration';
import containerYardConfirmations from './containerYardConfirmations';
import consigneeDeliveryPreferences from './consigneeDeliveryPreferences';
import attachments from './attachments';
import zendeskMasterTickets from './zendeskMasterTickets';
import users from './users';

const coreReducer = combineReducers({
  containerSizes,
  departments,
  emptyReturnAcceptance,
  stops,
  containerOnHireStatuses,
  terminalScraperConfigurations: terminalScraperConfigurationsReducer,
  chassis,
  chassisPools,
  chassisProviders,
  containerYardConfirmations,
  consigneeDeliveryPreferences,
  attachments,
  zendeskMasterTickets,
  users
});

export * from './containerSizes';
export * from './departments';
export * from './emptyReturnAcceptance';
export * from './stops';
export * from './terminalScraperConfiguration';
export * from './generatorBundles';
export * from './generatorLegs';
export * from './generatorBundleLegs';
export * from './containerOnHireStatuses';
export * from './chassisPools/index';
export * from './chassisProviders/index';
export * from './chassis/index';
export * from './consigneeDeliveryPreferences/index';
export * from './attachments';
export * from './zendeskMasterTickets';
export * from './users';

export default coreReducer;
