import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { isStopDepartment } from '@client/utils/data-processing/department';
import type { RootState } from '@client/reduxProvider';
import * as constants from '../../../../constants';
import { api } from '../../../../utils/url';
import request from '../../../../utils/request';
import { raiseToast } from '../../../../components/Toaster';
import SystemToast from '../../../../components/SystemToast';
import { getAuthToken } from '../../../../utils/auth';
import Department from './model';
import { DepartmentsById } from './types';

export const departmentsAdapter = createEntityAdapter<Department>({
  selectId: (item: Department) => item.uuid
});

// eslint-disable-next-line no-underscore-dangle
export const selectDepartments = (state: RootState) => state._departments;

export const fetchDepartments = createAsyncThunk<Department[] | undefined, void, { state: RootState }>(
  '_departments/fetchAll',
  async (_, thunkApi) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/departments`);

    try {
      const { data } = (await request<Record<'data', Department[]>>(url, options)) || { data: [] };

      return data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Departments" />);
      thunkApi.abort();
    }
  },
  {
    condition: (_, { getState }) => {
      const { loading } = selectDepartments(getState());

      if (loading) {
        return false;
      }
    }
  }
);

export const fetchDepartmentById = createAsyncThunk<
  Department | undefined,
  { departmentUuid: string; opts?: { triggerLoadingState?: boolean } },
  { state: RootState }
>('_departments/fetchById', async ({ departmentUuid }, thunkApi) => {
  const authHeader = getAuthToken();

  const options: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  const url = api(`/departments/${departmentUuid}`);

  try {
    const { data } = (await request<Record<'data', Department>>(url, options)) || {};

    return data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Department by uuid" />);

    thunkApi.abort();
  }
});

const departmentsSlice = createSlice({
  name: 'core/departmentsSlice',
  initialState: departmentsAdapter.getInitialState<{ loading: boolean; fetchAllLastFetchedAt: number | null }>({
    loading: false,
    fetchAllLastFetchedAt: null
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      const data = action.payload || [];

      departmentsAdapter.setAll(state, data);
      state.loading = false;
      state.fetchAllLastFetchedAt = Date.now();
    });
    builder.addCase(fetchDepartments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDepartments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchDepartmentById.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;

      if (!data) {
        return state;
      }

      departmentsAdapter.setOne(state, data);
    });
    builder.addCase(fetchDepartmentById.pending, (state, action) => {
      /**
       * In specific cases we might not want to trigger an entire page refresh. Defaults to always going into a loading state
       *   unless overriden not to
       */
      if (action.meta.arg.opts?.triggerLoadingState ?? true) {
        state.loading = true;
      }
    });
    builder.addCase(fetchDepartmentById.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { selectAll: selectAllDepartments } = departmentsAdapter.getSelectors(selectDepartments);

const CUSTOMER_TYPE = 'customer';
const CONSIGNEE_TYPE = 'consignee';
const STEAM_SHIPPING_LINE_TYPE = 'shipping_line';
const TERMINAL_TYPE = 'terminal';
const CARRIER_TYPE = 'carrier';
const YARD_TYPE = 'yard';
const CHASSIS_POOL_TYPE = 'chassis_pool';
const SHIPPING_LINE_TYPE = 'shipping_line';

export const selectDepartmentsLoading = createSelector(selectDepartments, (departments) => departments.loading);

export const selectShippers = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === STEAM_SHIPPING_LINE_TYPE)
);

export const selectTerminals = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === TERMINAL_TYPE)
);

export const selectCarriers = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === CARRIER_TYPE)
);

export const selectCustomers = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === CUSTOMER_TYPE)
);

export const selectConsignees = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === CONSIGNEE_TYPE)
);

export const selectYards = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === YARD_TYPE)
);

export const selectChassisPoolDepartments = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === CHASSIS_POOL_TYPE)
);

export const selectShippingLineDepartments = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.type === SHIPPING_LINE_TYPE)
);

export const selectStopDepartments = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => isStopDepartment(department.type))
);

// eslint-disable-next-line no-underscore-dangle
export const selectDepartmentsById = (state: RootState) => state._departments.entities as Record<string, Department>;

export const shippersAlphabetically = createSelector(selectShippers, (customers) => {
  const alphabetically = customers.sort((a, b) => (a.name < b.name ? -1 : 1));

  return alphabetically;
});

export const selectCustomersAlphabetically = createSelector(selectCustomers, (customers) => {
  const alphabetically = customers.sort((a, b) => (a.name < b.name ? -1 : 1));

  return alphabetically;
});

export const customersWithGlobal = createSelector(selectCustomersAlphabetically, (customers) => {
  const globalCustomer = {
    uuid: constants.PER_DIEM_GLOBAL_CUSTOMER_UUID_LOCAL,
    name: 'Global'
  } as Department;

  const withGlobal = [...customers];
  withGlobal.unshift(globalCustomer);

  return withGlobal;
});

export const selectSteamShippingLinesMap = createSelector(selectShippers, (shippers) => {
  const map: Record<string, string> = {};
  shippers.forEach((shipper) => {
    const { uuid, nickname, name } = shipper;
    map[uuid] = nickname || name;
  });
  return map;
});

export const selectSteamShippingLinesOptions = createSelector(selectShippers, (shippers) =>
  shippers
    .map((shipper) => ({ label: shipper.nickname || shipper.name, value: shipper.uuid }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectTerminalOptions = createSelector(selectTerminals, (terminals) =>
  terminals
    .filter((t) => t.nickname)
    .map((t) => ({ label: t.nickname || t.name, value: t.uuid }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectCustomerOptions = createSelector(selectCustomers, (customers) =>
  customers
    .map((customer) => ({ label: customer.nickname || customer.name, value: customer.uuid }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectStopOptions = createSelector(selectStopDepartments, (customers) =>
  customers
    .map((customer) => ({ label: customer.nickname || customer.name, value: customer.uuid }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectTerminalUuidMap = createSelector(selectTerminals, (terminals) => {
  const map: DepartmentsById = {};
  terminals.forEach((t) => {
    map[t.uuid] = t;
  });
  return map;
});

export const selectDepartmentUuidMap = createSelector(selectAllDepartments, (departments) => {
  const map: DepartmentsById = {};
  departments.forEach((d) => {
    map[d.uuid] = d;
  });
  return map;
});

export default departmentsSlice.reducer;
