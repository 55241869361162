import React from 'react';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../utils/url';
import request from '../../../../utils/request';
import { raiseToast } from '../../../../components/Toaster';
import SystemToast from '../../../../components/SystemToast';

export interface ContainerSize {
  uuid: string;
  length: number;
  type: string;
}

export const fetchContainerSizes = createAsyncThunk('core/containerSizes/fetchAll', async () => {
  const url = api(`/chassis-sizes`);

  try {
    const result = (await request(url)) as { data: ContainerSize[] };
    return result.data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Container Sizes" />);
  }
});

export const containerSizesAdapter = createEntityAdapter<ContainerSize>({
  selectId: (item: ContainerSize) => item.uuid
});

const containerSizesSlice = createSlice({
  name: 'core/containerSizes',
  initialState: containerSizesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContainerSizes.fulfilled, (state, action) => {
      const data = action.payload || [];

      containerSizesAdapter.setAll(state, data);
    });
  }
});

export const selectContainerSizes = (state: any) => state.core.containerSizes;

export const createSelectSizeUuidBySizeString = (sizeString: string) => {
  if (!sizeString) {
    return () => undefined;
  }
  const containerLength = Number(sizeString.slice(0, 2));
  const containerType = sizeString.slice(2).toLowerCase();
  return (state: any) => Object.values(state.core.containerSizes.entities as Record<string, ContainerSize>).find(
      (entity: ContainerSize) => entity.length === containerLength && entity.type === containerType
    )?.uuid;
};

export const { selectAll: selectAllContainerSizes, selectEntities: selectAllContainersById } =
  containerSizesAdapter.getSelectors<any>(selectContainerSizes);

export default containerSizesSlice.reducer;
