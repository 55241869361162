import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export interface GeneratorBundle {
  id: string;
  legs: any[];
  tags: string[];
  legCount: number;
}

export const fetchGeneratorBundles = createAsyncThunk('core/generatorBundles/fetch', async () => {
  const url = api('/bundle/generator/bundle');

  try {
    const response = (await request(url)) as { data: any[] };
    return response.data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Generator Bundles" />);
  }
});

const generatorBundleAdapter = createEntityAdapter<GeneratorBundle>({
  selectId: (generatorBundle) => generatorBundle.id
});

export const generatorBundleSlice = createSlice({
  name: 'core/generatorBundles',
  initialState: { status: 'idle', ...generatorBundleAdapter.getInitialState() },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGeneratorBundles.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      generatorBundleAdapter.setAll(state, action.payload || []);
    });
    builder.addCase(fetchGeneratorBundles.pending, (state) => {
      state.status = 'pending';
    });
  }
});

export const selectGeneratorBundles = (state: any) => state.core.generatorBundles;

export const { selectAll: selectAllGeneratorBundles } =
  generatorBundleAdapter.getSelectors<any>(selectGeneratorBundles);

export default generatorBundleSlice.reducer;
