import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import { getAuthToken } from '@client/utils/auth';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export interface TerminalScraperConfiguration {
  uuid: string;
  terminalUuid: string;
  availabilitySupported: boolean;
  availabilityEnabled: boolean;
  regionScanSupported: boolean;
  regionScanEnabled: boolean;
  outgateVerificationSupported: boolean;
  outgateVerificationEnabled: boolean;
  ingateVerificationSupported: boolean;
  ingateVerificationEnabled: boolean;
  appointmentBookingSupported: boolean;
  appointmentBookingEnabled: boolean;
  onHireSupported: boolean;
  onHireEnabled: boolean;
  ingateAppointmentBookingEnabled: boolean;
  ingateAppointmentBookingSupported: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

const flattenAttributes = (data: any) => data.map((item: any) => item.attributes);

export const fetchTerminalScraperConfigurations = createAsyncThunk(
  'core/terminalScraperConfigurationsSlice/fetchAll',
  async () => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/scraper-configuration`);

    try {
      const response = await request<{ data: { attributes: TerminalScraperConfiguration[] } }>(url, options);
      return response?.data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Scraper Configurations" />);
    }
  }
);

export const terminalScraperConfigurationAdapter = createEntityAdapter<any>({
  selectId: (item) => item.uuid
});

export const selectTerminalScraperConfigurations = (state: any) => state.core.terminalScraperConfigurations;

export const { selectAll: selectAllTerminalScraperConfigurations } = terminalScraperConfigurationAdapter.getSelectors(
  selectTerminalScraperConfigurations
);

export const selectTerminalScraperConfigurationsData = (state: any) => state.core.terminalScraperConfigurations;

export const selectHasTerminalAPIUpdates = (state: any) => selectTerminalScraperConfigurationsData(state)?.hasUpdates;

const terminalScraperConfigurationSlice = createSlice({
  name: 'core/terminalScraperConfigurationSlice',
  initialState: terminalScraperConfigurationAdapter.getInitialState({
    loading: false
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTerminalScraperConfigurations.fulfilled, (state, action) => {
      const data = action.payload ? flattenAttributes(action.payload) : [];
      terminalScraperConfigurationAdapter.setAll(state, data);
      state.loading = false;
    });
    builder.addCase(fetchTerminalScraperConfigurations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTerminalScraperConfigurations.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const terminalScraperConfigurationsReducer = terminalScraperConfigurationSlice.reducer;
export const terminalScraperConfigurationsActions = terminalScraperConfigurationSlice.actions;
