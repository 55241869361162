export interface AsyncAtomState {
  data: any;
  loading: number;
  error: null | any;
}

export default (): AsyncAtomState => ({
  data: null,
  loading: 0,
  error: null
});
