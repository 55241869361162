import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export interface GeneratorLeg {
  legNumber: number;
  containerNumber: string;
  chassisNumber: string;
  chassisProviderName: string;
  steamShippingLineAccountUuid: string;
  billingAccountUuid?: string;
  size: string;
  importExport: string;
  deliveryType: 'droppick' | 'drop_only' | 'live';
  status: string;
  carrierName?: string;
  carrierUuid?: string;
  driverName?: string;
  driverPhone?: string;
  driverUuid?: string;
  truckUuid?: string;
  firstStopDepartmentUuid: string;
  firstStopType: string;
  firstStopName: string;
  firstStopStart: string;
  firstStopEnd: string;
  lastStopDepartmentUuid: string;
  lastStopType: string;
  lastStopName: string;
  lastStopStart: string;
  lastStopEnd: string;
  lastStopTbd: boolean;
  earliestAppointmentStart: string;
  latestAppointmentStart: string;
  bundleBefore?: string;
  bundleAfter?: string;
  visibility: string;
  emptyReadyAt: string;
  legEstimatedDistance?: number;
  lastFreeDay: string;
  perDiemDueDate: string;
  stopCount?: number;
  confirmedAtYard: number | null;
  firstStopAppointmentNumber: string | null;
  lastStopAppointmentNumber: string | null;
  steamShippingLineAccountName: string | null;
}

export const fetchGeneratorLegs = createAsyncThunk('core/generatorLeg/fetch', async () => {
  const url = api('/bundle/generator/leg');

  try {
    const response = (await request(url)) as { data: any[] };
    return response.data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Generator Legs" />);
  }
});

const generatorLegsAdapter = createEntityAdapter<GeneratorLeg>({
  selectId: (generatorLeg) => generatorLeg.legNumber
});

export const generatorLegsSlice = createSlice({
  name: 'core/generatorLegs',
  initialState: { status: 'idle', ...generatorLegsAdapter.getInitialState() },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGeneratorLegs.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      generatorLegsAdapter.setAll(state, action.payload || []);
    });
    builder.addCase(fetchGeneratorLegs.pending, (state) => {
      state.status = 'pending';
    });
  }
});

export const selectGeneratorLegs = (state: any) => state.core.generatorLegs;

export const selectGeneratorLegsById = (state: any) => state.core.generatorLegs.entities;

export const { selectAll: selectAllGeneratorLegs } = generatorLegsAdapter.getSelectors<any>(selectGeneratorLegs);

export default generatorLegsSlice.reducer;
