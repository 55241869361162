import React from 'react';
import SystemToast from '@client/components/SystemToast';
import { raiseToast, TOAST_POSITION } from '@client/components/Toaster';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector, EntityState } from '@reduxjs/toolkit';
import { getAuthToken } from '@client/utils/auth';
import { selectChassisById } from '../chassis';

export interface ChassisPoolEntity {
  uuid: string;
  abbreviation: string;
  badgeName: string | null;
  badgeColor: string | null;
  name: string | null;
  description: string | null;
}

export type ChassisPoolsEntityAdapter = { attributes: ChassisPoolEntity };

export interface ChassisPoolsState extends EntityState<any> {
  loading: boolean;
  error: boolean;
}

export interface ChassisPoolsFilterProps {
  badgeType?: string;
  attributes?: string;
}

const initialState = {
  loading: false,
  error: false
};

export function sendSuccessNotification(message: string): void {
  raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message={message} />, {
    position: TOAST_POSITION.TOP_LEFT
  });
}

export function sendFailureNotification(message: string): void {
  raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={message} />, {
    position: TOAST_POSITION.TOP_LEFT
  });
}

export const fetchChassisPools = createAsyncThunk('chassis-pool/fetchAll', async () => {
  const authHeader = getAuthToken();

  const options: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  const url = api('/chassis-pool');

  try {
    const response = await request(url, options);
    return response.data;
  } catch (err) {
    // handle toast error
    const message = 'Unable to fetch chassis pools information';
    sendFailureNotification(message);
  }
});

export const upsertChassisPool = createAsyncThunk(
  'chassis-pool/upsertChassisPool',
  async (chassisPoolInfo: ChassisPoolEntity[]) => {
    try {
      const url = api('/chassis-pool');
      const opts = { method: 'PUT', body: JSON.stringify({ data: chassisPoolInfo }) };
      const { data } = await request(url, opts);
      return data;
    } catch (err) {
      const message = 'Unable to create or update chassis pools.';
      sendFailureNotification(message);
    }
  }
);

export const deleteBulkChassisPool = createAsyncThunk(
  'chassis-pool/deleteBulkChassisPools',
  async (chassisPoolUuids: string[]) => {
    try {
      const url = api('/chassis-pool');
      const opts = { method: 'DELETE', body: JSON.stringify({ chassisPoolUuids }) };
      await request(url, opts);
      return chassisPoolUuids;
    } catch (err) {
      const message = 'Unable to delete chassis pools.';
      sendFailureNotification(message);
    }
    // return data;
  }
);

export const chassisPoolsAdapter = createEntityAdapter<ChassisPoolEntity>({
  selectId: (chassisPools: any) => chassisPools?.uuid,
  sortComparer: (a, b) => a.abbreviation.localeCompare(b.abbreviation)
});

const chassisPoolsSlice = createSlice({
  name: 'core/chassisPoolsSlice',
  initialState: chassisPoolsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChassisPools.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (action.payload) {
        chassisPoolsAdapter.setAll(state, data);
      }
    });

    // PUT
    builder.addCase(upsertChassisPool.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertChassisPool.fulfilled, (state, action) => {
      state.loading = true;
      const data = action.payload || [];
      if (action.payload) {
        chassisPoolsAdapter.upsertMany(state, data);
        const message = 'Chassis Pool created or updated!';
        return sendSuccessNotification(message);
      }
    });

    // DELETE
    builder.addCase(deleteBulkChassisPool.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkChassisPool.fulfilled, (state, action) => {
      state.loading = true;
      const deletedChassisPoolUuids = action.payload || [];

      if (action.payload) {
        chassisPoolsAdapter.removeMany(state, deletedChassisPoolUuids);
        const message = 'Chassis Pools Deleted!';
        return sendSuccessNotification(message);
      }
    });
  }
});

const selectChassisPools = (state: any): ChassisPoolsState => state.core.chassisPools;

export const {
  selectAll: selectAllChassisPools,
  selectById: selectChassisPoolById,
  selectEntities: selectChassisPoolsById
} = chassisPoolsAdapter.getSelectors<any>(selectChassisPools);

export const chassisPoolsForPut = (selectedUuid: string) =>
  createSelector(selectAllChassisPools as any, (chassisPools: ChassisPoolEntity[]) => {
    const result = chassisPools.filter(({ uuid }) => selectedUuid === uuid);
    return result;
  });

const selectStore = (state: any) => state;

export const filteredChassisPoolsSelectorFactory = ({ badgeType, attributes }: ChassisPoolsFilterProps) =>
  createSelector(selectAllChassisPools as any, (chassisPools: ChassisPoolEntity[]) => {
    const result = chassisPools.filter(
      (chassisPool) => chassisPool[badgeType as keyof ChassisPoolEntity] === attributes
    );
    return result;
  });

export const chassisPoolByIdSelectorFactory = (uuid: string) =>
  createSelector(selectStore, (state) => selectChassisPoolById(state, uuid));

export const selectChassisPoolDropdownOptions = createSelector(selectAllChassisPools, (state) =>
  state.map((chassisPool) => ({
    value: chassisPool.uuid,
    label: chassisPool.name
  }))
);

export const selectedChassisPoolDropdownOptionsWithNullSelection = createSelector(
  selectChassisPoolDropdownOptions,
  (state) => [{ value: 'N/A', label: 'N/A' }, ...state]
);

export const selectChassisPoolByTermFactory = (query: string) =>
  createSelector(selectAllChassisPools as any, (chassisPool: ChassisPoolEntity[]) => {
    const hashed = {} as { [uuid: string]: ChassisPoolEntity };
    const parsedString = query.split(/[, ]+/);

    parsedString.forEach((str) => {
      chassisPool.forEach((cPool) => {
        if (hashed[cPool.uuid] === undefined) {
          if (
            cPool.abbreviation.toLowerCase().search(str.toLowerCase()) !== -1 ||
            cPool.badgeName?.toLowerCase().search(str.toLowerCase()) !== -1
          ) {
            hashed[cPool.uuid] = cPool;
          }
        }
      });
    });

    const result = Object.values(hashed) as ChassisPoolEntity[];
    return result;
  });

export const chassisPoolByChassisIdSelectorFactory = (chassisNumber: string) =>
  createSelector(selectStore, (state) => {
    const chassis = selectChassisById(state, chassisNumber);
    return selectChassisPoolById(state, chassis?.chassisPoolUuidOverride || chassis?.chassisPoolUuid);
  });

export default chassisPoolsSlice.reducer;
