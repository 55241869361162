import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import type { RootState } from '@client/reduxProvider';
import { sendFailureNotification } from '../../utils/toastNotifications';
import { ConsigneeDeliveryPreferences } from './model';

export const sliceID = 'core/consigneeDeliveryPreferencesSlice';

export const getConsigneeDeliveryPreferenceLoadingKey = (consigneeUuid: string, type: 'import' | 'export') =>
  `${consigneeUuid}.${type}`;

export const fetchConsigneeDeliveryPreferencesByDepartmentAndType = createAsyncThunk(
  'consigneeDeliveryPreferences/fetchByDepartmentAndType',
  async ({ departmentUuid, type = 'import' }: { departmentUuid: string; type: 'import' | 'export' }, thunkApi) => {
    const loadingKey = getConsigneeDeliveryPreferenceLoadingKey(departmentUuid, type);
    const state = thunkApi.getState() as RootState;
    const isLoading = state.core.consigneeDeliveryPreferences.loadingByKey[loadingKey];
    if (isLoading) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    thunkApi.dispatch(setKeyLoading({ key: loadingKey, loading: true }));
    const url = api(`/consignee-delivery-preferences?departmentUuid=${departmentUuid}&type=${type}`);
    try {
      const response = await request(url);
      return response.data;
    } catch (err) {
      sendFailureNotification('Failed to fetch consignee delivery preferences', 'bottom-left');
      return [];
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      thunkApi.dispatch(setKeyLoading({ key: loadingKey, loading: false }));
    }
  }
);

export const fetchConsigneeDeliveryPreferences = createAsyncThunk('consigneeDeliveryPreferences/fetchAll', async () => {
  const url = api('/consignee-delivery-preferences');
  try {
    const response = await request(url);
    return response.data;
  } catch (err) {
    sendFailureNotification('Failed to fetch consignee delivery preferences', 'bottom-left');
    return [];
  }
});

export const consigneeDeliveryPreferencesAdapter = createEntityAdapter<ConsigneeDeliveryPreferences>({
  selectId: (item: ConsigneeDeliveryPreferences) => item.id
});

const initialState: { loadingByKey: Record<string, boolean>; allLoading: boolean } = {
  loadingByKey: {},
  allLoading: false
};

const consigneeDeliveryPreferencesSlice = createSlice({
  name: sliceID,
  initialState: consigneeDeliveryPreferencesAdapter.getInitialState(initialState),
  reducers: {
    setKeyLoading: (state, action: { payload: { key: string; loading: boolean } }) => {
      state.loadingByKey[action.payload.key] = action.payload.loading;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConsigneeDeliveryPreferencesByDepartmentAndType.fulfilled, (state, action) => {
      if (action.payload) {
        consigneeDeliveryPreferencesAdapter.upsertMany(state, action.payload);
      }
    });

    builder.addCase(fetchConsigneeDeliveryPreferences.fulfilled, (state, action) => {
      state.allLoading = false;
      if (action.payload) {
        consigneeDeliveryPreferencesAdapter.upsertMany(state, action.payload);
      }
    });
    builder.addCase(fetchConsigneeDeliveryPreferences.rejected, (state) => {
      state.allLoading = false;
    });
    builder.addCase(fetchConsigneeDeliveryPreferences.pending, (state) => {
      state.allLoading = true;
    });
  }
});

export const { setKeyLoading } = consigneeDeliveryPreferencesSlice.actions;

export default consigneeDeliveryPreferencesSlice.reducer;
