import request, { requestRules } from '@client/utils/request';
import { api } from '@client/utils/url';

export const retrieveDriverJobs = async (params: { driverID: string }, opts?: { abortKey?: string }): Promise<any> => {
  const url = api(`/admin/driver-jobs/${params.driverID}`);
  const options: Parameters<typeof request>[1] = {
    method: 'GET',
    credentials: 'same-origin',
    details: opts?.abortKey
      ? {
          id: opts.abortKey,
          rule: requestRules.TAKE_LAST
        }
      : undefined
  };

  const response: any = await request(url, options);
  return response;
};
