import React from 'react';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../utils/url';
import request from '../../../../utils/request';
import { raiseToast } from '../../../../components/Toaster';
import SystemToast from '../../../../components/SystemToast';

export interface ContainerYardConfirmationUserResponse {
  firstName: string;
  lastName: string;
}

export interface ContainerYardConfirmationResponse {
  containerNumber: string;
  confirmedBy?: string;
  confirmedAt: string;
  reservedBy: string;
  reservedAt: string;
  discrepancy?: string;
  loadType: 'empty' | 'loaded';
  category?: string;
  notes: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  checkInDate: string;
  booking: string;
  location: string;
  confirmedByUser?: ContainerYardConfirmationUserResponse;
  reservedByUser?: ContainerYardConfirmationUserResponse;
}

export interface PutContainerYardConfirmationRequest {
  containerNumber?: string;
  confirmedBy?: string;
  reservedBy?: string;
  notes?: string;
}

export const fetchContainerYardConfirmations = createAsyncThunk(
  'core/containerYardConfirmations/fetchAll',
  async () => {
    const url = api(`/container-yard-confirmations`);

    try {
      const result = (await request(url)) as { data: ContainerYardConfirmationResponse[] };
      return result.data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch container yard confirmations" />);
    }
  }
);

export const putContainerYardConfirmation = createAsyncThunk(
  'core/containerYardConfirmations/put',
  async (arg: PutContainerYardConfirmationRequest) => {
    const url = api(`/container-yard-confirmations`);

    try {
      const result = (await request(url, {
        method: 'PUT',
        body: JSON.stringify(arg)
      })) as { data: ContainerYardConfirmationResponse };
      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Yard confirmation updated" />);
      return result.data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update confirmation" />);
    }
  }
);

export const containerYardConfirmationsAdapter = createEntityAdapter<ContainerYardConfirmationResponse>({
  selectId: (item: ContainerYardConfirmationResponse) => item.containerNumber
});

const containerYardConfirmationsSlice = createSlice({
  name: 'core/containerYardConfirmations',
  initialState: containerYardConfirmationsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContainerYardConfirmations.fulfilled, (state, action) => {
      const data = action.payload || [];

      containerYardConfirmationsAdapter.setAll(state, data);
    });
    builder.addCase(putContainerYardConfirmation.fulfilled, (state, action) => {
      const data = (action.payload || {}) as ContainerYardConfirmationResponse;

      containerYardConfirmationsAdapter.setOne(state, data);
    });
  }
});

export const selectContainerYardConfirmationsMap = (state: any) => state.core.containerYardConfirmations.entities;

export default containerYardConfirmationsSlice.reducer;
