import { useSelector } from 'react-redux';
import { apptOutreachEnabledSelectorFactory } from './selectors';

export function useConsigneeDeliveryPreferences({
  consigneeUuid,
  importExport
}: {
  consigneeUuid: string;
  importExport: string;
}) {
  const apptOutreachEnabled = useSelector(apptOutreachEnabledSelectorFactory(consigneeUuid, importExport));

  return { apptOutreachEnabled };
}
